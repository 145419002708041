import { md } from "./markdown";
import contentfulContent from "./data.json";

export interface WebsiteSection {
  // contentfulID
  cid: string;
  contentHTML: string;
}

export const websiteSections = contentfulContent.entries
  .filter(
    (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "section"
  )
  .map((e): WebsiteSection => {
    return {
      cid: e.sys.id,
      contentHTML: md.render(e.fields.contentMarkdown!["en-US"]),
    };
  });
