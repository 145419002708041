













import { Component, Vue, Prop } from "vue-property-decorator";
import { AdventurePost } from "@/data/adventure";

@Component({})
export default class AdventurePostView extends Vue {
  @Prop() post!: AdventurePost;
}
