



















import { Component, Vue } from "vue-property-decorator";
import { blogPosts } from "@/data/blogPost";

@Component({})
export default class PowerBlogHome extends Vue {
  blogPosts = blogPosts;
}
