
















import { Component, Vue, Prop } from "vue-property-decorator";
import { BlogPost } from "@/data/blogPost";

@Component({})
export default class PowerBlogPost extends Vue {
  @Prop() post!: BlogPost;
}
