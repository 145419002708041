















import { Component, Vue, Prop } from "vue-property-decorator";
import { ProjectPost } from "@/data/projectPost";

@Component({})
export default class ProjectPostView extends Vue {
  @Prop() post!: ProjectPost;
}
