import contentfulContent from "./data.json";
import { websiteSections, WebsiteSection } from "./websiteSection";

export interface WebsitePage {
  slug: string;
  htmlTitle: string;
  contentBlocks: WebsiteSection[];
}

const websitePageEntries = contentfulContent.entries.filter(
  (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "page"
);

export const websitePages = websitePageEntries.map((p): WebsitePage => {
  const contents = p.fields.sections!["en-US"].map((wc): WebsiteSection => {
    return websiteSections.find((c) => c.cid === wc.sys.id)!;
  });
  return {
    slug: p.fields.slug!["en-US"],
    htmlTitle: (p.fields.name || { "en-US": "" })!["en-US"],
    contentBlocks: contents,
  };
});
