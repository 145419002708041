import { assetFiles } from "./contentful";
import contentfulContent from "./data.json";
import { md } from "./markdown";

export interface AdventurePost {
  slug: string;
  title: string;
  date: string;
  imageCoverURL: string;
}

const entries = contentfulContent.entries.filter(
  (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "tripReport"
);

export const adventurePosts = entries.map((e): AdventurePost => {
  const imageCover = assetFiles.find(
    (f) => f.sysid === e.fields.imageCover!["en-US"].sys.id
  );
  const dt = new Date(Date.parse(e.fields.dateStart!["en-US"]));
  const dateStr = `${dt.toString().split(" ")[1]} ${dt.getFullYear()}`;
  return {
    slug: e.fields.slug!["en-US"],
    title: (e.fields.name || { "en-US": "" })!["en-US"],
    date: dateStr,
    imageCoverURL: imageCover ? imageCover.url : "",
  };
});
