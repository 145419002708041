












import { Component, Prop, Vue } from "vue-property-decorator";
import type { WebsitePage } from "@/data/websitePage";
import WebsiteSection from "@/components/WebsiteSection.vue";

@Component({ components: { WebsiteSection } })
export default class WebsitePageView extends Vue {
  @Prop() page!: WebsitePage;
}
