import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import Home from "@/views/Home.vue";
import Home from "@/views/HomeSimple.vue";
import WebsitePage from "@/views/WebsitePage.vue";
import PowerBlog from "@/views/PowerBlog.vue";
import AdventureRandom from "@/views/AdventureRandom.vue";
import PowerBlogPost from "@/views/PowerBlogPost.vue";
import ProjectPostView from "@/views/ProjectPost.vue";
import AdventurePostView from "@/views/Adventure.vue";
import NotFoundPage from "@/views/404.vue";
import { websitePages } from "@/data/websitePage";
import { blogPosts } from "@/data/blogPost";
import { projectPosts } from "@/data/projectPost";
import { adventurePosts } from "@/data/adventure";

Vue.use(VueRouter);

const pageRoutes = websitePages
  .filter((p: any) => p.slug !== "work")
  .map((p: any): RouteConfig => {
    return {
      path: `/${p.slug}`,
      component: WebsitePage,
      props: { page: p },
    };
  });
const blogRoutes = blogPosts.map((p: any): RouteConfig => {
  return {
    path: `/power-blog/${p.slug}`,
    component: PowerBlogPost,
    props: { post: p },
  };
});
const projectRoutes = projectPosts.map((p: any): RouteConfig => {
  return {
    path: `/projects/${p.slug}`,
    component: ProjectPostView,
    props: { post: p },
  };
});
const adventureRoutes = adventurePosts.map((p: any): RouteConfig => {
  return {
    path: `/adventures/${p.slug}`,
    component: AdventurePostView,
    props: { post: p },
  };
});

const redirects: RouteConfig[] = [
  {
    path: "/bike-rambles",
    redirect: "/projects/bike-rambles",
  },
];

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/power-blog",
    name: "Power Blog",
    component: PowerBlog,
  },
  // {
  //   path: "/adventure",
  //   name: "Adventure",
  //   component: AdventureRandom,
  // },
  ...pageRoutes,
  ...blogRoutes,
  ...projectRoutes,
  // ...adventureRoutes,
  ...redirects,
  { path: "*", component: NotFoundPage },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // ref: https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      // back button
      return savedPosition;
    }
    // scroll to top on new page
    return { x: 0, y: 0 };
  },
});

export default router;
