




import { Component, Prop, Vue } from "vue-property-decorator";
import type { WebsiteSection } from "@/data/websiteSection";

@Component({})
export default class WebsiteSectionView extends Vue {
  @Prop() content!: WebsiteSection;
}
