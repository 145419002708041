import contentfulContent from "./data.json";
import { md } from "./markdown";

export interface BlogPost {
  slug: string;
  title: string;
  date: string;
  skipInListings: boolean;
  contentHTML: string;
}

const blogPostEntries = contentfulContent.entries.filter(
  (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "blog-post"
);

export const blogPosts = blogPostEntries
  .filter((e) => e.fields.published!["en-US"])
  .map((e): BlogPost => {
    return {
      slug: e.fields.slug!["en-US"],
      title: (e.fields.title || { "en-US": "" })!["en-US"],
      date: e.fields.date!["en-US"].split("T")[0],
      skipInListings: e.fields.skipInListings!["en-US"],
      contentHTML: md.render(e.fields.contentMarkdown!["en-US"]),
    };
  });
