import { assetFiles } from "./contentful";
import contentfulContent from "./data.json";
import { md } from "./markdown";

export interface ProjectPost {
  slug: string;
  title: string;
  imageCoverURL: string;
  contentHTML: string;
}

const projectEntries = contentfulContent.entries.filter(
  (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "project"
);

export const projectPosts = projectEntries.map((e): ProjectPost => {
  const imageCover = assetFiles.find(
    (f) => f.sysid === e.fields.imageCover!["en-US"].sys.id
  );
  return {
    slug: e.fields.slug!["en-US"],
    title: (e.fields.title || { "en-US": "" })!["en-US"],
    imageCoverURL: imageCover ? imageCover.url : "",
    contentHTML: md.render(e.fields.mainContent!["en-US"]),
  };
});
